import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { Router, globalHistory } from '@reach/router'
import { Redirect } from '@reach/router'
import { API, Cache } from 'aws-amplify'
import ShippingConfirmAlternative from 'components/page/signup/ShippingConfirmAlternative'
import Supplements from 'components/page/signup/Supplements'
import PublicRoute from 'components/route/PublicRoute'
import { gb, loadGrowthbook } from 'components/util/Growthbook'
import { getVisitorId } from 'components/util/UserId'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import {
  resetHashes,
  resetSingleHashes,
  setHashChanged,
  setHashLoaded,
  updateCouponHash,
  updateProductHash
} from 'state/actions/hashAction'
import { updateKey } from 'state/actions/keyAction'
import { resetPlan } from 'state/actions/planAction'
import { getProducts } from 'state/actions/productsAction'
import { checkHash } from 'state/apis/hashApi'
import { DogType } from 'state/types/dogType'
import PrivacyPolicy from '../../components/page/privacy-policy'
import AddTreats from '../../components/page/signup/AddTreats'
import Complete from '../../components/page/signup/Complete'
import Recipes from '../../components/page/signup/Recipes'
import ShippingConfirm from '../../components/page/signup/ShippingConfirm'
import Treats from '../../components/page/signup/Treats'
import Dogs from '../../components/page/signup/dogs'
import DogsAlternative from '../../components/page/signup/dogsAlternative'
import ParentDetails from '../../components/page/signup/parentDetails/ParentDetails'
import Plan from '../../components/page/signup/plans'
import Layout from '../../components/ui/layout/Layout'

const App = () => {
  const [_userId, setUserId] = useState('')

  let visitorId: string = Cache.getItem('visitorId')
  if (
    [
      '638c084f1f16cf85aed66cf027472004',
      'baefc2443c952ce9109898d9016853d4',
      '696ab977b1e177303a0c0023240ddfdf',
      '814e1afd9f329f4c5aa6e32f9ee9568a',
      '1795d46ba0721e30c39ec35f47e981dd'
    ].includes(visitorId)
  )
    visitorId = ''

  const getUserId = async () => {
    const visitorId = await getVisitorId()
    setUserId(visitorId)
  }
  // Send BE pageview tracking
  const sendPageviewEvent = async () => {
    if (window?.location?.pathname) {
      if (visitorId) {
        setUserId(visitorId)
        await API.post('wwwREST', '/v1/public/event', {
          body: {
            path: window?.location?.pathname,
            visitorId,
            name: 'pageview'
          }
        }).catch((err: any) => {
          console.error(JSON.stringify(err, null, 2))
        })
      } else {
        await getUserId()
      }
    }
  }
  const Routes = () => {
    const { planHash, couponHash, productListHash, hashLoaded } = useSelector((state: RootState) => state.hash)
    const { key } = useSelector((state: RootState) => state.key)
    const { pack, dogInfo, packNew }: { pack: any; dogInfo: DogType; packNew: any } = useSelector(
      (state: RootState) => state.dog
    )
    const products = useSelector((state: RootState) => state.products)
    // Get productListHash from product store
    // TODO: Can be updated once product store in place
    const { productListHash: productHash } = products || { productListHash: '' }
    // const { productListHash } = useSelector((state: RootState) => state.products)

    const dispatch = useDispatch()
    const dogDetailsPage = 'dog-details'

    useEffect(() => {
      // Populates the product store
      // if (!products)
      dispatch(getProducts())
      dispatch(setHashChanged(false))
    }, [])

    useEffect(() => {
      // this handles the productListHash check for plan page
      // TODO: consider moving to plan page otherwise calls on every route
      if (productHash && productHash !== productListHash) {
        dispatch(updateProductHash(productHash))
      }
    }, [productHash, productListHash])

    useEffect(() => {
      const postCheckHash = async () => {
        try {
          dispatch(setHashLoaded(false))
          await checkHash({
            planLevel: pack.planLevel,
            hashValuePlan: packNew.hash || planHash,
            hashValueCoupon: couponHash,
            hashValueProducts: productListHash,
            visitorId: dogInfo?.visitorId
          })
          dispatch(setHashLoaded(true))
        } catch (e: any) {
          dispatch(setHashLoaded(false))
          if (!e?.hashValueProducts && e?.hashValuePlan) {
            // only reset the product list hash
            await dispatch(resetSingleHashes('productListHash'))
            // Only redirect if after the plan page
            if (
              !window.location.pathname.includes('your-details') &&
              !window.location.pathname.includes('dog-details')
            ) {
              dispatch(updateKey(`plan`))
              navigate(`/signup/plan`)
            }
          } else if (!e?.hashValuePlan) {
            // only reset the plan hash
            await dispatch(resetSingleHashes('planHash'))
            await dispatch(setHashChanged(true))
            dispatch(updateKey(`plan`))
            navigate(`/signup/plan`)
          } else {
            console.error('Dog details hash redirect - ', e)
            await dispatch(resetHashes())
            await dispatch(resetPlan())
            dispatch(updateKey(`${dogDetailsPage}`))
            navigate(`/signup/${dogDetailsPage}`)
          }
        }
      }

      if (
        planHash &&
        couponHash &&
        productListHash &&
        !['dog-details', 'dog-details-1', 'add-treats', 'supplements', 'thankyou'].includes(key)
      ) {
        postCheckHash()
      }
    }, [planHash, packNew, couponHash, productListHash, key])

    return (
      <Layout>
        <Helmet
          bodyAttributes={{
            class: 'signup'
          }}
        ></Helmet>
        <Router basepath="/signup">
          <Redirect from="/" to={`/signup/${dogDetailsPage}`} />
          <PublicRoute path="/dog-details-1" component={Dogs} />
          <PublicRoute path="/dog-details" component={DogsAlternative} />
          <PublicRoute path="/your-details" component={ParentDetails} />
          <PublicRoute path="/plan" component={Plan} />
          <PublicRoute path="/recipes" component={Recipes} />
          <PublicRoute path="/treats" component={Treats} />
          <PublicRoute path="/checkout-1" component={ShippingConfirm} />
          <PublicRoute path="/checkout" component={ShippingConfirmAlternative} />
          <AddTreats path="/add-treats" />
          <Supplements path="/supplements" />
          <Complete path="/thankyou" />
          <PrivacyPolicy path="/privacy-policy" />
        </Router>
      </Layout>
    )
  }

  let gbKey = process.env.GATSBY_GROWTHBOOK_KEY

  useEffect(() => {
    loadGrowthbook(gb)
    sendPageviewEvent()
  }, [])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') sendPageviewEvent()
    })
  }, [])

  return (
    <>
      {gbKey && (
        <GrowthBookProvider growthbook={gb}>
          <Routes />
        </GrowthBookProvider>
      )}
      {!gbKey && <Routes />}
    </>
  )
}

export default App
