import { navigate, useLocation } from '@reach/router'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateCouponCodeValidity, updateCurrCouponCode, updateCustomerEmail } from 'state/actions/dogAction'
import { updateKey } from 'state/actions/keyAction'
import {
  createParentDetails,
  resetLoadingStatus,
  updateChangedStatus,
  updateCouponCode,
  updateParentEmail
} from 'state/actions/parentAction'
import { CouponType, CouponsType, DogType, SummaryType } from 'state/types/dogType'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Cache } from 'aws-amplify'
import { Field, Formik, useFormikContext } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import Slider from 'react-slick'
import * as yup from 'yup'

import Coupon from 'components/ui/coupon/Coupon'
import CouponAlternative from 'components/ui/coupon/CouponAlternative'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import TrustBox from 'components/ui/trustpilot/TrustBox'
import { stringFormatter } from 'utils/string-formatter'
import { ParentDetailAlternativeSchema } from 'utils/yup-validation-schema'
import { sliderData } from './SliderData'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DDIcon from '../../../../images/dd-icon.png'
import Cook from '../../../../images/dog-details/cook.svg'
import Delivery from '../../../../images/dog-details/delivery.svg'
import EmptyBowl from '../../../../images/dog-details/empty-bowl.svg'
import Fussy from '../../../../images/dog-details/fussy-dog.svg'
import Ingredients from '../../../../images/dog-details/ingredients.svg'
import Pause from '../../../../images/dog-details/pause.svg'
import Vet from '../../../../images/dog-details/vet-support.svg'

const ParentDetails = () => {
  const dispatch = useDispatch()
  const {
    dogInfo,
    summary,
    coupon,
    isValidCoupon,
    currCouponCode,
    lastValidatedCoupon,
    lastValidatedCoupons,
    loading: isCouponValidationLoading,
    planLevel
  }: {
    dogInfo: DogType
    summary: any
    coupon: CouponType
    isValidCoupon: boolean
    currCouponCode: string
    lastValidatedCoupon: CouponType
    lastValidatedCoupons: CouponsType[]
    loading: boolean
    planLevel: number
  } = useSelector((state: RootState) => state.dog)
  const { key } = useSelector((state: RootState) => state.key)
  const { parentDetails, errorMessage, changed, loading } = useSelector((state: RootState) => state.parent)

  const [couponCode, setCouponCode] = useState<string>('')
  const [showPopup, setShowPopup] = useState(false)
  const [nextButtonVisible, setNextButtonVisible] = useState(true)
  const [fussyDiscount, setFussyDiscount] = useState<30 | 35 | 40>(30)

  var formikRef = useRef<any>()
  const location = useLocation()

  const checkoutV3 = useFeatureIsOn('checkout-version-three')
  const pupParentFieldShown = useFeatureValue('pup-parent-field-shown', 0)
  const fussyDogDiscount = useFeatureIsOn('fussy-dog-discount-coupon')

  const replaceableCoupons = ['REAL30', 'NEW35', 'FAST40']

  const {
    customer: { marketingOptIn },
    dogs,
    visitorId = null
  } = dogInfo

  const dogDetailsPage = 'dog-details'

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  }

  useEffect(() => {
    dispatch(resetLoadingStatus())
  }, [])

  useEffect(() => {
    // Apply fussy dog discount to first dog with medium or low fussiness
    if (fussyDogDiscount) {
      const fussyLevel = dogs?.filter(d => d.fussy && !d.fussy.includes('HIGH')).map(d => d.fussy)
      if (fussyLevel && fussyLevel.length > 0) {
        if (fussyLevel[0].includes('MEDIUM')) {
          setFussyDiscount(35)
          return
        }
        if (fussyLevel[0].includes('LOW')) {
          setFussyDiscount(40)
          return
        }
      }
    }
  }, [dogs, fussyDogDiscount])

  useEffect(() => {
    // To stop auto scroll on page load for mobile
    // window?.scrollTo(0, 0)
    let nextButton = document.getElementById('parentButtonModule')
    if (nextButton) {
      const buttonRect = nextButton.getBoundingClientRect()
      // console.error('Pup parent buttonRect:', buttonRect)
      const isFullyInView = buttonRect.top >= 0 && buttonRect.bottom <= window.innerHeight
      if (isFullyInView) {
        setNextButtonVisible(true)
      } else {
        setNextButtonVisible(false)
      }
    }
    const isButtonInView = () => {
      if (nextButton) {
        const buttonRect = nextButton.getBoundingClientRect()
        const isButtonInView = buttonRect.bottom > 120 && buttonRect.top < window.innerHeight
        if (isButtonInView) {
          setNextButtonVisible(true)
        } else {
          setNextButtonVisible(false)
        }
      }
    }
    document.addEventListener('scroll', isButtonInView)
    return () => {
      document.removeEventListener('scroll', isButtonInView)
    }
  }, [])

  // Backend Growthbook event
  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && dogInfo?.growthbook) {
      const experiments = Array.isArray(dogInfo.growthbook) ? dogInfo.growthbook : [dogInfo.growthbook]
      experiments.forEach(experiment => {
        if (experiment.experiment_id) {
          console.log('Log BE experiment to Growthbook', experiment)
          window.gtag('event', experiment.event_name || 'viewed_experiment', experiment)
        }
      })
    }
  }, [dogInfo?.growthbook])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('ViewContent')
    }
    if (planLevel) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'opened_page', {
          page: 'sign_up_pup_parent',
          planLevel
        })
      }
    }
  }, [planLevel])

  useEffect(() => {
    if (currCouponCode) setCouponCode(currCouponCode)
    else setCouponCode(coupon.code)
  }, [coupon, currCouponCode])

  useEffect(() => {
    // Priorise the QS sign up flow coupon, then look for a content page coupon, otherwise we fallback to the default
    const { coupon: couponFromQueryStr } = parse(location.search)
    let coupon = couponFromQueryStr ?? Cache.getItem('coupon') ?? null

    if (coupon) {
      coupon = coupon.toUpperCase()
      setCouponCode(coupon as string)
      dispatch(updateCouponCodeValidity('checkValidity', coupon as string))
    }
  }, [])

  useEffect(() => {
    // Apply fussy dog discount if last validated discount is a replacable coupon
    if (
      fussyDogDiscount &&
      replaceableCoupons.includes(lastValidatedCoupon?.code) &&
      replaceableCoupons.includes(couponCode)
    ) {
      if (fussyDogDiscount && fussyDiscount === 30 && lastValidatedCoupon.code !== 'REAL30') {
        setCouponCode('REAL30' as string)
        dispatch(updateCouponCodeValidity('checkValidity', 'REAL30' as string))
      }
      if (fussyDogDiscount && fussyDiscount === 35 && lastValidatedCoupon.code !== 'NEW35') {
        setCouponCode('NEW35' as string)
        dispatch(updateCouponCodeValidity('checkValidity', 'NEW35' as string))
      }
      if (fussyDogDiscount && fussyDiscount === 40 && lastValidatedCoupon.code !== 'FAST40') {
        setCouponCode('FAST40' as string)
        dispatch(updateCouponCodeValidity('checkValidity', 'FAST40' as string))
      }
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'fussy_level_coupon_applied', {
          page: 'sign_up_your_details',
          event_category: 'fussy_level_coupon_applied',
          event_label: 'fussy_level_coupon_applied',
          coupon: fussyDiscount === 30 ? 'REAL30' : fussyDiscount === 35 ? 'NEW35' : 'FAST40'
        })
      }
    }
  }, [fussyDogDiscount, fussyDiscount, lastValidatedCoupon, couponCode])

  useEffect(() => {
    if (errorMessage?.field === 'email') {
      formikRef.current.setFieldError('email', errorMessage?.message)
    }

    if (errorMessage && errorMessage.popup) {
      dispatch(updateParentEmail(''))
      dispatch(updateCustomerEmail(''))
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (key !== 'your-details') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [])

  useEffect(() => {
    if (changed) {
      dispatch(updateKey('plan'))
      navigate(`/signup/plan/${location.search && '?' + stringify(parse(location.search))}`)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  const backClick = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'clicked_back', {
        page: 'sign_up_your_details'
      })
    }

    dispatch(updateKey(`${dogDetailsPage}`))
    navigate(`/signup/${dogDetailsPage}/${location.search && '?' + stringify(parse(location.search))}`)
  }

  const handleParentFormData = (values: any, isOnChange: boolean) => {
    const data = {
      customer: {
        name: values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1).toLowerCase(),
        surname: '',
        email: values.email,
        phone: values.phoneNumber,
        marketingOptIn: values.offers
      },
      coupon: couponCode as string,
      dogs,
      visitorId
    }
    if (values.firstName && values.firstName.includes(' ')) {
      let name = values.firstName.split(' ')
      if (name.length >= 2) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'surname_entered', {
            page: 'sign_up_pup_parent'
          })
        }
        let firstName = name[0]
        name.shift()
        let surname = name.join(' ')
        data.customer.name = stringFormatter(firstName)
        data.customer.surname = stringFormatter(surname)
      }
    } else {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'surname_not_entered', {
          page: 'sign_up_pup_parent'
        })
      }
    }
    isOnChange && dispatch(createParentDetails(data, false))
    return data
  }

  const ExtendedParentDetailAlternativeSchema = ParentDetailAlternativeSchema.shape({
    firstName: yup.string().when([], {
      is: () => Number(pupParentFieldShown) === 0 || Number(pupParentFieldShown) === 2,
      then: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z_.\-' ]+$/, 'Name cannot contain special characters')
        .required('Name is required'),
      otherwise: yup.string()
    }),

    phoneNumber: yup.string().when([], {
      is: () => Number(pupParentFieldShown) === 2 || Number(pupParentFieldShown) === 3,
      then: yup
        .string()
        .required('Please enter a correct mobile number')
        .matches(/^(?:\+44|44|0)(1|2|7)\d{9}$/, 'Please enter a valid mobile number'),
      otherwise: yup.string()
    })
  })

  const getDogNames = () => {
    return summary
      .map(d => d.name)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' &')
  }

  const getSliderImg = (image: string) => {
    switch (image) {
      case 'Cook':
        return <img className="relative mr-6 flex-shrink-0" alt="Cook" width={80} src={Cook} />
      case 'Delivery':
        return <img className="relative mr-6 flex-shrink-0" alt="Delivery" width={80} src={Delivery} />
      case 'EmptyBowl':
        return <img className="relative mr-6 flex-shrink-0" alt="Empty bowl" width={80} src={EmptyBowl} />
      case 'Fussy':
        return <img className="relative mr-6 flex-shrink-0" alt="Fussy dog" width={80} src={Fussy} />
      case 'Ingredients':
        return <img className="relative mr-6 flex-shrink-0" alt="Ingredients" width={80} src={Ingredients} />
      case 'Pause':
        return <img className="relative mr-6 flex-shrink-0" alt="Pause" width={80} src={Pause} />
      case 'Vet':
        return <img className="relative mr-6 flex-shrink-0" alt="Vet" width={80} src={Vet} />
      default: {
        return <img className="relative mr-6 flex-shrink-0" alt="Empty bowl" width={80} src={EmptyBowl} />
      }
    }
  }

  const getSlideItems = () => {
    return sliderData.map((item: Image, i: number) => {
      return (
        <div key={i} className="custom-slider-item w-full">
          <div className="flex flex-col items-center justify-center text-right md:flex-row w-full md:w-2/3 mx-auto">
            {getSliderImg(item.image)}

            <div className="text-center md:text-start">
              <p className="h3 mb-4 text-xl md:text-2xl font-medium font-serif">{item.title}</p>
              <p>{item.description}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="relative overflow-hidden">
        <div
          className={`z-index-1 header-wave-new ${
            summary?.length > 1
              ? 'h-[890px] sm:h-[790px] md:h-[930px] xl:h-[890px]'
              : 'h-[780px] sm:h-[720px] md:h-[800px] xl:h-[790px]'
          }`}
        />
        <div className="relative z-index-2">
          <Formik
            initialValues={{
              firstName: parentDetails.customer.surname
                ? parentDetails.customer.name + ' ' + parentDetails.customer.surname
                : parentDetails.customer.name,
              email: parentDetails.customer.email || dogInfo.customer.email,
              phoneNumber: parentDetails.customer.phone,
              offers:
                typeof parentDetails.customer.marketingOptIn === 'boolean'
                  ? parentDetails.customer.marketingOptIn
                  : marketingOptIn,
              discount: parentDetails.coupon
            }}
            innerRef={formikRef}
            validationSchema={ExtendedParentDetailAlternativeSchema}
            onSubmit={async values => {
              if (values) {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'clicked_next', {
                    page: 'sign_up_your_details'
                  })
                }

                if (typeof ttq !== 'undefined') {
                  ttq.track('CompleteRegistration')
                }

                const couponVal = isValidCoupon
                  ? couponCode
                  : lastValidatedCoupon.code
                    ? lastValidatedCoupon.code
                    : coupon.code
                setCouponCode(couponVal)
                console.error(
                  `on Submit: Pup parent coupon - ${JSON.stringify(couponVal)} couponCode - ${JSON.stringify(couponCode)} lastValidatedCoupon - ${JSON.stringify(lastValidatedCoupon.code)} coupon.code - ${JSON.stringify(coupon.code)}`
                )
                dispatch(updateCouponCodeValidity('checkValidity', couponVal))
                console.error(`onSubmit: Pup parent - ${JSON.stringify(values)}`)
                const formData = handleParentFormData(values, false)
                formData['coupon'] = couponVal as string
                formData['dogs'] = dogInfo.dogs.filter(d => d.name)
                dispatch(createParentDetails(formData, true))

                dispatch(updateCurrCouponCode(couponVal as string))
                dispatch(updateCouponCode(couponVal as string))
                dispatch(updateCustomerEmail(values.email))
                // DS: Tech debt set coupon cache override for replaceable coupons
                if (replaceableCoupons.includes(couponVal)) {
                  Cache.setItem('coupon', couponVal.toUpperCase() as string)
                }
              }
              // handlePupParentSubmit(values)
            }}
            validateOnMount={false}
          >
            {props => (
              <div>
                <FormikErrorFocus
                  formik={useFormikContext()}
                  offset={0}
                  align={'middle'}
                  focusDelay={1000}
                  ease={'linear'}
                  duration={1000}
                />
                <div className="mx-auto grid max-w-[90%] grid-cols-1 gap-y-4 mt-12">
                  <div>
                    {summary?.length > 0 && (
                      <div className="relative flex flex-col">
                        {summary?.length === 1 && (
                          <h2 className="m-auto mb-4 text-center text-3xl md:text-4xl">{summary[0]?.name} needs</h2>
                        )}
                        {summary?.length > 1 && (
                          <h2 className="m-auto mb-4 text-center text-3xl md:text-4xl">
                            Your dogs will need a total of
                          </h2>
                        )}
                        <h2 className="m-auto mb-4 text-center text-2xl md:text-5xl">
                          <span>
                            {summary
                              ?.map((dog: SummaryType) => dog.kcalPerDay)
                              .reduce((a: number, b: number) => a + b)}{' '}
                          </span>
                          <span className="text-secondary">Kcals</span> /{' '}
                          <span>
                            {summary
                              ?.map((dog: SummaryType) => dog.gramsPerDay)
                              .reduce((a: number, b: number) => a + b)}
                            {'g '}
                          </span>
                          <span className="text-secondary">per day</span>
                        </h2>
                        <div className="m-auto w-full md:w-3/4 text-xs md:text-base">
                          <p className="mb-4 md:mb-6 text-center text-sm md:text-base">
                            Recommended daily intake for a healthy balanced diet.
                          </p>
                        </div>
                        {summary?.length > 1 && (
                          <div className="flex justify-center items-center w-full lg:w-5/6 mx-auto flex-col md:flex-row mt-2 md:mt-0 md:mb-2">
                            {summary?.map((dog: SummaryType) => {
                              return (
                                <>
                                  {/* Mobile view */}
                                  <div className="flex flex-row w-full mx-auto justify-center items-center md:hidden mb-2">
                                    <div className="grid grid-cols-3 gap-4">
                                      <div className="col-start-1 col-span-1 flex flex-row justify-start items-center ">
                                        {' '}
                                        <img width={20} src={DDIcon} alt={'Different Dog logo'} />
                                        <span className="font-bold mx-2 md:mr-0">{dog.name}</span>
                                      </div>
                                      <div className="col-start-2 col-span-2 flex justify-center items-center">
                                        {' '}
                                        <p className="font-sans-serif text-base">
                                          <span>
                                            {dog.kcalPerDay} <span className="text-secondary mr-1">Kcals</span>
                                          </span>
                                          /<span className="ml-1">{dog.gramsPerDay}g</span>{' '}
                                          <span className="text-secondary">per day</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Desktop view */}
                                  <div className="w-1/5 hidden md:flex flex-col ">
                                    <div className="col-span-1 flex flex-row justify-center items-center mb-2">
                                      <img width={20} src={DDIcon} alt={'Different Dog logo'} />
                                      <span className="font-bold ml-1">{dog.name}</span>
                                    </div>
                                    <div className="flex-row hidden md:block">
                                      <p className="font-sans-serif mb-2 text-center text-base">
                                        <span>
                                          {dog.kcalPerDay} <span className="text-secondary">Kcals</span>
                                        </span>
                                        <br />
                                        <span className="">{dog.gramsPerDay}g</span>{' '}
                                        <span className="text-secondary">per day</span>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        )}
                        <div className="px-0 mx-auto w-full relative md:w-3/4 md:px-8 lg:w-1/2">
                          <div className="flex flex-row items-center justify-center">
                            <StaticImage
                              objectFit={'contain'}
                              className="mr-2 flex-shrink-0"
                              alt="Bone"
                              width={40}
                              src="../../../images/dog-details/bone.svg"
                            />
                            <p className="text-center text-xl md:text-3xl font-third">
                              We've left a little room for treats
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {fussyDogDiscount &&
                    (couponCode === 'NEW35' || couponCode === 'FAST40') &&
                    (fussyDiscount === 35 || fussyDiscount === 40) && (
                      <div className="bg-ddBlue relative rounded-3xl w-full md:w-1/2 p-4 mx-auto mb-2">
                        <p className="text-center text-[#004355] text-sm md:text-base font-sans font-normal">
                          You've unleashed an <span className="font-bold">exclusive discount!</span>
                          <br className="hidden lg:block" /> Sign up today and fetch{' '}
                          <span className="font-bold">
                            {fussyDiscount}% off <br className="lg:hidden" /> your first box.
                          </span>
                          <br className="hidden lg:block" /> Hurry before this treat disappears!
                        </p>
                      </div>
                    )}
                  <div className="bg-white relative rounded-3xl w-full md:w-1/2 p-4 mx-auto">
                    <div>
                      <h3 className="mb-4 text-center">
                        {getDogNames()}'s personalised plan is ready to go! <br />
                        {Number(pupParentFieldShown) !== 1
                          ? 'But first, what are your details?'
                          : 'But first, add your email.'}
                      </h3>
                      {/* Name */}
                      {(Number(pupParentFieldShown) === 0 || Number(pupParentFieldShown) === 2) && (
                        <div className="mx-auto flex w-full flex-col justify-center items-center mb-4">
                          <div className="w-11/12">
                            <div className="relative flex w-full flex-col">
                              <Field
                                data-testid="firstName"
                                id="fName"
                                name="firstName"
                                type="text"
                                className="fName peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight"
                                onBlur={() => {
                                  handleParentFormData(props.values, true)

                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_first_name', {
                                      page: 'sign_up_your_details',
                                      first_name: props.values.firstName
                                    })
                                  }
                                }}
                                onChange={e => {
                                  props.setFieldValue('firstName', e.target.value)
                                }}
                              />
                              <label
                                onClick={() => {
                                  document.querySelector('.fName')?.focus()
                                }}
                                className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.firstName ? 'ml-0 -translate-y-3 scale-75' : ''
                                }`}
                                htmlFor="yourName"
                              >
                                Your name
                                <span className="ml-1">*</span>
                              </label>
                            </div>
                          </div>
                          {props.errors.firstName && props.touched.firstName ? (
                            <div className="p-2 text-sm text-red-500">{props.errors.firstName}</div>
                          ) : null}
                        </div>
                      )}
                      {/* Email */}
                      <div className="mx-auto flex w-full flex-col justify-center items-center mb-4">
                        <div className="w-11/12">
                          <div className="relative flex w-full flex-col">
                            <Field
                              data-testid="email"
                              name="email"
                              label="Email"
                              type="text"
                              className="email peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight"
                              onBlur={() => {
                                handleParentFormData(props.values, true)

                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'entered_email', {
                                    page: 'sign_up_your_details',
                                    email: props.values.email
                                  })
                                }
                              }}
                              onChange={(e: any) => {
                                props.setFieldValue('email', e.target.value.trim())
                              }}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                  handleParentFormData(props.values, true)
                                  props.handleSubmit()
                                }
                              }}
                            />
                            <label
                              onClick={() => {
                                document.querySelector('.email')?.focus()
                              }}
                              className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                props.values.email ? 'ml-0 -translate-y-3 scale-75' : ''
                              }`}
                              htmlFor="email"
                            >
                              Email address<span className="ml-1">*</span>
                            </label>
                          </div>
                        </div>
                        <div>
                          {props.errors.email && props.touched.email ? (
                            <div className="p-2 text-sm text-red-500">{props.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      {/* Phone number */}
                      {(Number(pupParentFieldShown) === 2 || Number(pupParentFieldShown) === 3) && (
                        <div className="mx-auto flex w-full flex-col justify-center items-center mb-4">
                          <div className="w-11/12">
                            <div className="relative flex w-full flex-col">
                              <Field
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone number"
                                type="tel"
                                autoComplete="off"
                                className="phoneNumber peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight"
                                onBlur={() => {
                                  handleParentFormData(props.values, true)

                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_phone_number', {
                                      page: 'sign_up_your_details',
                                      phoneNumber: props.values.phoneNumber
                                    })
                                  }
                                }}
                                onChange={(e: any) => {
                                  props.setFieldValue('phoneNumber', e.target.value)
                                }}
                                onKeyDown={(e: any) => {
                                  if (e.key === 'Enter') {
                                    handleParentFormData(props.values, true)
                                    props.handleSubmit()
                                  }
                                }}
                              />
                              <label
                                onClick={() => {
                                  document.querySelector('.phoneNumber')?.focus()
                                }}
                                className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.phoneNumber ? 'ml-0 -translate-y-3 scale-75' : ''
                                }`}
                                htmlFor="phoneNumber"
                              >
                                Phone number<span className="ml-1">*</span>
                              </label>
                            </div>
                          </div>
                          <div>
                            {props.errors.phoneNumber && props.touched.phoneNumber ? (
                              <div className="p-2 text-sm text-red-500">{props.errors.phoneNumber}</div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={`mx-auto flex ${!isValidCoupon ? 'w-full' : 'w-11/12'} flex-row justify-center mb-4`}
                    >
                      {!checkoutV3 && (
                        <div
                          className={`flex items-center justify-center ${
                            isValidCoupon && lastValidatedCoupon.amount > 0 && 'bg-ddBlue'
                          } m-auto w-full rounded-full`}
                        >
                          <div className={`flex flex-col ${isValidCoupon ? `w-1/2` : 'w-11/12'}`}>
                            <Coupon
                              defaultCouponCode={coupon.code}
                              couponCode={couponCode}
                              setCouponCode={setCouponCode}
                              isValid={isValidCoupon}
                              isCouponValidationLoading={isCouponValidationLoading}
                              labelClass="inherit text-center transform-none"
                              CouponChangeGA={{
                                category: 'Sign_Up_Your_Details',
                                action: 'Clicked_Discount',
                                label: 'submit'
                              }}
                              validateFailGA={{
                                category: 'Sign_Up_Your_Details',
                                action: 'Invalid_Discount',
                                label: 'submit'
                              }}
                              isRounded={true}
                              page={'pupParent'}
                            />
                          </div>
                          {isValidCoupon && lastValidatedCoupons?.length > 1 && (
                            <p className="relative flex flex-grow justify-center text-center text-sm text-black md:text-xs">
                              {lastValidatedCoupons[0]?.type === 'percentage'
                                ? lastValidatedCoupons[0].amount + '% off'
                                : '£' + (lastValidatedCoupons[0].amount / 100).toFixed(2) + ' off'}{' '}
                              {lastValidatedCoupons[0].uses > 1
                                ? `your first ${lastValidatedCoupons[0].uses} boxes`
                                : 'your first box'}
                              <br />
                              {lastValidatedCoupons[1].type === 'percentage'
                                ? lastValidatedCoupons[1].amount + '% off'
                                : '£' + (lastValidatedCoupons[1].amount / 100).toFixed(2) + ' off'}{' '}
                              {lastValidatedCoupons[1].uses > 1
                                ? `your next ${lastValidatedCoupons[1].uses} boxes`
                                : 'your first box'}
                            </p>
                          )}
                          {isValidCoupon && lastValidatedCoupon.amount > 0 && lastValidatedCoupons?.length <= 1 && (
                            <p className="relative flex flex-grow justify-center text-center text-sm text-black md:text-base">
                              {lastValidatedCoupon.type === 'percentage'
                                ? lastValidatedCoupon.amount + '% off'
                                : '£' + (lastValidatedCoupon.amount / 100).toFixed(2) + ' off'}{' '}
                              {lastValidatedCoupon.uses > 1
                                ? `your first ${lastValidatedCoupon.uses} boxes`
                                : 'your first box'}
                            </p>
                          )}
                        </div>
                      )}
                      {checkoutV3 && (
                        <CouponAlternative
                          defaultCouponCode={coupon.code}
                          couponCode={couponCode}
                          setCouponCode={setCouponCode}
                          isValid={isValidCoupon}
                          isCouponValidationLoading={isCouponValidationLoading}
                          labelClass="inherit text-center transform-none"
                          CouponChangeGA={{
                            category: 'Sign_Up_Your_Details',
                            action: 'Clicked_Discount',
                            label: 'submit'
                          }}
                          validateFailGA={{
                            category: 'Sign_Up_Your_Details',
                            action: 'Invalid_Discount',
                            label: 'submit'
                          }}
                          isRounded={true}
                          page={'checkoutAlternate'}
                        />
                      )}
                    </div>
                    {fussyDogDiscount &&
                      (couponCode === 'NEW35' || couponCode === 'FAST40') &&
                      (fussyDiscount === 35 || fussyDiscount === 40) && (
                        <div className="flex flex-row justify-center items-center pb-3 -mt-1">
                          <StaticImage
                            width={18}
                            className="flex justify-center items-center mr-2"
                            src="../../../../images/Celebrate.svg"
                            alt="Celebrate"
                          />
                          <p className="text-center text-[#004355] text-sm font-sans font-medium">
                            {fussyDiscount}% off your first box applied
                          </p>
                        </div>
                      )}
                    <div className="mx-auto flex w-full flex-row justify-center lg:w-full mb-4">
                      <button
                        data-testid="parentButton"
                        id="parentButtonModule"
                        onClick={() => {
                          props.handleSubmit()
                        }}
                        disabled={loading}
                        type="submit"
                        className={`${
                          loading ? '!bg-[#80B2C2]' : ''
                        } btn-cta scale-animation w-11/12 !px-8 !py-2 !text-xl sm:!px-16`}
                      >
                        {loading ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          'Next'
                        )}
                      </button>
                    </div>
                    <div className="w-full md:w-3/4 mx-auto">
                      <div role="group" aria-labelledby="offers-group" className="text-center">
                        <div className="flex flex-row mb-3">
                          <label className="text-xs font-sans flex justify-center items-center ">
                            <Field
                              data-testid="offersOpt"
                              id="offers1"
                              type="checkbox"
                              name="offers"
                              className="text-fourth-checkbox h-4 w-4 rounded-[6px]"
                            />
                          </label>
                          <span className="ml-3 md:ml-4 text-left font-medium text-xs font-sans">
                            Want tips from our vet, tasty discounts and cute dogs in your inbox?
                          </span>
                        </div>
                        <a
                          className="text-sm font-sans text-primary underline underline-offset-4"
                          target="_blank"
                          href="/signup/privacy-policy"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        {props.errors.offers && props.touched.offers ? (
                          <div className="text-sm text-red-500">{props.errors.offers}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* Bottom page section */}
                  {/* Slider section */}
                  <Slider {...sliderSettings} className="h-66 md:h-36 w-full relative mx-auto mt-6 md:mt-16">
                    {getSlideItems()}
                  </Slider>
                  <div>
                    {/* Bottom image section */}
                    <div className="flex flex-col md:flex-row my-4 md:mt-12 md:mb-2 mx-auto w-full justify-center items-center">
                      <div className="flex flex-row">
                        <div>
                          {' '}
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-6 flex-shrink-0 max-w-lg"
                            alt="Dog licking bowl"
                            src="../../../../images/pup-parent/your-details-one.png"
                          />
                        </div>
                        <div>
                          {' '}
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-6 flex-shrink-0 max-w-lg"
                            alt="Chef"
                            src="../../../../images/pup-parent/your-details-two.png"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div>
                          {' '}
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-6 flex-shrink-0 max-w-lg"
                            alt="Packaging"
                            src="../../../../images/pup-parent/your-details-three.png"
                          />
                        </div>
                        <div>
                          {' '}
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-6 flex-shrink-0 max-w-lg"
                            alt="Dog"
                            src="../../../../images/pup-parent/pp-four.png"
                          />
                        </div>
                      </div>
                    </div>
                    {/* Bottom help section */}
                    <div className="mt-10 md:mt-24 mb-0 md:mb-8">
                      <div className="flex flex-col items-center justify-center text-right md:flex-row mx-auto">
                        <StaticImage
                          objectFit={'contain'}
                          className="mr-6 flex-shrink-0"
                          alt="Chat"
                          width={60}
                          src="../../../../images/chat.png"
                        />
                        <div className="text-center md:text-start mt-2 md:mt-0">
                          <p className="h3 text-sm md:text-2xl font-medium font-serif">
                            Call us on 01743 384 562 with any questions.
                            <br />
                            We're always happy to help.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <StickyFooter className="z-[3] fixed bottom-0 left-0 right-0 custom:max-h-20 bg-[#F8F2EC] w-full custom:w-11/12 mx-auto custom:rounded-t-3xl shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                  <div
                    className={`w-full custom:w-full flex flex-col custom:flex-row relative items-center justify-between mx-auto ${!nextButtonVisible ? '' : 'custom:py-1'}`}
                  >
                    <button
                      data-testid="parentBack"
                      onClick={backClick}
                      type="button"
                      className="rounded-full border border-primary bg-white px-12 py-3 hover:underline focus:underline hidden custom:flex justify-center items-center"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4 mr-2" aria-hidden="true" />
                      Back
                    </button>

                    {/* Coupon display */}
                    {coupon?.newCustomerTreatOffer && (
                      <div className="flex flex-row justify-center custom:items-center">
                        {' '}
                        {nextButtonVisible && (
                          <a
                            className="mr-2 flex h-8 w-8 items-center text-primary custom:hidden rounded-full border border-primary bg-white justify-center"
                            onClick={backClick}
                          >
                            <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
                          </a>
                        )}
                        <div className="flex justify-center items-center rounded-3xl bg-fourth px-4 mb-2 custom:mb-0 custom:absolute custom:left-1/2 custom:transform custom:-translate-x-1/2">
                          <div className="p-2 flex items-center justify-center">
                            <StaticImage
                              objectFit="contain"
                              className="mr-2 flex-shrink-0"
                              alt="Bone"
                              width={20}
                              src="../../../images/dog-details/bone.svg"
                            />
                            <p className="text-center text-xs font-sans">
                              <span className="font-bold">FREE Treat</span> on your first order
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {!nextButtonVisible && (
                      <div className="flex flex-row justify-center items-center w-11/12 custom:w-auto custom:justify-center">
                        <a
                          className={`mr-2 flex h-[3.5rem] w-[3.5rem] items-center text-primary custom:hidden`}
                          onClick={backClick}
                        >
                          <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
                        </a>

                        <button
                          data-testid="planBtn"
                          onClick={() => props.handleSubmit()}
                          disabled={!props.isValid || loading}
                          type="button"
                          className={`${
                            !props.isValid || loading ? '!bg-[#80B2C2]' : ''
                          } btn-cta scale-animation !py-3 custom:!py-4 !text-xl !font-bold !flex justify-center items-center w-11/12 custom:w-auto custom:!px-24`}
                        >
                          {loading ? (
                            <div className="align-center flex justify-center">
                              <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                              <div>Saving</div>
                            </div>
                          ) : (
                            'Next'
                          )}
                          {!loading && (
                            <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </StickyFooter>
              </div>
            )}
          </Formik>
        </div>
      </div>
      {errorMessage?.popup && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup.message}
          url={errorMessage?.popup.url}
          action={errorMessage?.popup.action}
          actionText={errorMessage?.popup.actionText}
        />
      )}
      <TrustBox />
      {/* <FeedbackForm source={'signup'} /> */}
    </>
  )
}

export default ParentDetails
