import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import AddTreatConfirmModal from 'components/ui/modal/AddTreatConfirmModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import RecipeOrTreatAlterModal from 'components/ui/modal/RecipeOrTreatModalAlter'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import CdnImage from 'components/util/CdnImage'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updatePlans } from 'state/actions/account/accountPlanAction'
import { createDogsExistingPlan, resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { populateRecipeAndTreatData, populateTreatDataOnly } from 'state/actions/account/flow/planAction'
import { createShippingConfirmDogsExistingAddons } from 'state/actions/account/flow/shippingAction'
import { getAccountSummary, resetAccountSummary } from 'state/actions/accountSummary'
import { resetDogs } from 'state/actions/dogAction'
import { resetKey } from 'state/actions/keyAction'
import { resetParent } from 'state/actions/parentAction'
import { resetPlan } from 'state/actions/planAction'
import { resetShipping } from 'state/actions/shippingAction'
import { PlanType } from 'state/types/planType'
import { SupplementsSchema } from 'utils/yup-validation-schema'
import BritishFlag from '../../../images/BritishFlag.svg'
import BulletPointImage from '../../../images/BulletPoint.svg'
import limitedEditionSticker from '../../../images/limited-edition-sticker.svg'
import loadingGIF from '../../../images/loadingspinner.gif'
import NewSticker from '../../../images/new-sticker.svg'

const Supplements = () => {
  const {
    summary
  }: {
    summary: AccountSummaryType
  } = useSelector((state: RootState) => state.accountSummary)
  const { lastValidatedCoupon } = useSelector((state: RootState) => state.dog)
  const {
    plan
  }: {
    plan: PlanType
  } = useSelector((state: RootState) => state.accountDogPlanFlow)
  const { orderSummary }: any = useSelector((state: RootState) => state.shippingConfirm)
  const { dogInfo, loading }: any = useSelector((state: RootState) => state.accountDogFlow)
  const { error, errorMessage } = useSelector((state: RootState) => state.accountDogShippingFlow)
  const [supplements, setSupplements] = useState<Array<{ id: string; quantity: number; price: number; name: string }>>(
    []
  )
  const [supplementCount, setSupplementCount] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [addTreatModalShow, setAddTreatModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [submitting, setSubmitting] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const dispatch = useDispatch()
  const [supplementChange, _setSupplementChange] = useState(0)
  const [supplementValue, _setSupplementValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [cognitoUser, setCognitoUser] = useState(null)
  const [startSession, setStartSession] = useState(false)
  const buttonText = supplementCount > 0 ? 'Confirm' : 'Skip'

  const supplementDetail: any = plan?.addons.filter(
    (addon: any) => addon.type !== 'TREAT' || addon.type === 'SUPPLEMENT'
  )

  useEffect(() => {
    // Navigate to account if the user clicks browser back button
    const handleBrowserBack = () => {
      navigate('/account/')
    }
    window.addEventListener('popstate', handleBrowserBack)

    return () => {
      window.removeEventListener('popstate', handleBrowserBack)
    }
  }, [])

  useEffect(() => {
    setSubscriptionId('')
    Auth.currentAuthenticatedUser()
      .then(user => {
        // console.log({ user })
        setCognitoUser(user)
        if (!user) {
          setCognitoUser(null)
          dispatch(resetKey())
          dispatch(resetParent())
          dispatch(resetDogs())
          dispatch(resetPlan())
          dispatch(resetShipping())
          setStartSession(false)
          navigate('/signup/dog-details')
        } else {
          // Get account summary to load redux
          const getAccountSummaryAndOrders = () => {
            dispatch(resetFlowDogs())
            dispatch(resetAccountSummary())
            dispatch(getAccountSummary())
            dispatch(updatePlans())
            setIsLoading(false)
          }
          setIsLoading(true)
          getAccountSummaryAndOrders()
          setStartSession(true)
        }
      })
      .catch(err => {
        console.error('add supplement auth error', err)
        setCognitoUser(null)
        dispatch(resetKey())
        dispatch(resetParent())
        dispatch(resetDogs())
        dispatch(resetPlan())
        dispatch(resetShipping())
        setStartSession(false)
        navigate('/signup/dog-details')
      })
  }, [])

  useEffect(() => {
    if (cognitoUser && orderSummary?.recipes?.length === 0) {
      navigate('/account/')
    }
  }, [orderSummary])

  useEffect(() => {
    // Handle plan
    if (startSession && summary?.plans) {
      const activePlans = summary?.plans?.filter(p => p.status === 'active' || p.status === 'future')
      if (activePlans?.length === 1) {
        setSubscriptionId(activePlans[0]?.subscriptionId)
      }
    }
  }, [summary, startSession])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    // Load dogInfo redux
    if (subscriptionId) {
      dispatch(
        createDogsExistingPlan({
          subscriptionId: subscriptionId,
          dogs: []
        })
      )
    }
  }, [subscriptionId])

  useEffect(() => {
    // Check mismatch in the store and correct it
    if (summary && summary?.plans[0]?.weeks !== plan?.weeks) {
      if (dogInfo?.defaultPackSize?.default) {
        const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
        dispatch(
          populateRecipeAndTreatData({
            plan: dogInfo.defaultPackSize[planType],
            noOfPacks: dogInfo.defaultPackSize[planType].numberOfPacks,
            packSize: dogInfo.defaultPackSize[planType].packSize,
            weeks: dogInfo.defaultPackSize[planType].weeks,
            isDefaultPlan: dogInfo.defaultPackSize.default !== 'CUSTOM',
            packType: dogInfo.defaultPackSize.default,
            fixedOrCustomPlan: dogInfo.defaultPackSize.default === 'CUSTOM' ? 'CUSTOM' : 'FIXED'
          })
        )
      }
    }
  }, [summary])

  useEffect(() => {
    // Load treats
    if (dogInfo?.defaultPackSize?.default && plan?.addons?.length === 0) {
      const planType = dogInfo.defaultPackSize.default.toLowerCase().replace('_t', 'T')
      dispatch(populateTreatDataOnly({ plan: dogInfo.defaultPackSize[planType] }))
    }
  }, [dogInfo, subscriptionId])

  useEffect(() => {
    if (errorMessage || errorMessage?.popup) {
      setAddTreatModalShow(false)
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
      window.gtag('event', 'view_item_list', {
        page: 'sign_up_supplement_post_checkout',
        item_list_id: 'supplements',
        item_list_name: 'Supplements',
        items: plan?.addons?.map(a => {
          return {
            item_id: a.id,
            item_name: a.name,
            coupon: lastValidatedCoupon.code,
            item_list_id: a.analytics?.item_list_id,
            item_list_name: a.analytics?.item_list_name
          }
        })
      })
    }
  }, [])

  const getDogNames = (dogs: any) => {
    return dogs
      .filter(d => d.name)
      .map(d => d.name)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' &')
  }

  const disableSupplementAddButton = (supplementId: string, outOfStock: boolean) => {
    const index = supplements.findIndex(supplement => supplement.id === supplementId)
    const previousSupplementIndex = plan?.addons.findIndex((supplement: any) => supplement.id === supplementId)

    if (index !== -1) {
      const supplementsArr = [...supplements]
      if (outOfStock) {
        if (supplementsArr[index].quantity >= plan?.addons[previousSupplementIndex].quantity) {
          return true
        }
      }
    } else {
      if (outOfStock) {
        if (plan?.addons[previousSupplementIndex].quantity > 0) {
          return false
        }
        return true
      }
    }
    return false
  }

  const handleAddSupplement = (supplementId: string, price: number, outOfStock: boolean, name: string) => {
    if (supplements) {
      const index = supplements.findIndex(supplement => supplement.id === supplementId)
      const previousSupplementIndex = plan?.addons.findIndex((addon: any) => addon.id === supplementId)

      if (index !== -1) {
        const supplementsArr = [...supplements]

        if (outOfStock) {
          if (supplementsArr[index].quantity < plan?.addons[previousSupplementIndex].quantity) {
            supplementsArr[index].quantity = supplementsArr[index].quantity + 1
            setSupplements(supplementsArr)
            setSupplementCount(curr => curr + 1)
          }
        } else {
          supplementsArr[index].quantity = supplementsArr[index].quantity + 1
          setSupplements(supplementsArr)
          setSupplementCount(curr => curr + 1)
        }
      } else {
        if (!outOfStock) {
          setSupplements([...supplements, { id: supplementId, quantity: 1, price, name }])
          setSupplementCount(curr => curr + 1)
        } else {
          if (plan?.addons[previousSupplementIndex].quantity > 0) {
            setSupplements([...supplements, { id: supplementId, quantity: 1, price, name }])
            setSupplementCount(curr => curr + 1)
          }
        }
      }
    } else {
      if (!outOfStock) {
        setSupplements([{ id: supplementId, quantity: 1, price, name }])
        setSupplementCount(curr => curr + 1)
      }
    }

    if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
      const index = plan?.addons?.findIndex(addon => addon.id === supplementId)
      const supplementIndex = supplements?.findIndex(supplement => supplement.id === supplementId)
      window.gtag('event', 'select_item', {
        item_list_id: 'supplements',
        item_list_name: 'Supplements',
        items: [
          {
            item_id: plan?.addons[index].id,
            item_name: plan?.addons[index].name,
            coupon: lastValidatedCoupon.code,
            item_list_id: plan?.addons[index].analytics?.item_list_id,
            item_list_name: plan?.addons[index].analytics?.item_list_name
          }
        ]
      })
      window.gtag('event', 'add_to_cart', {
        currency: 'GBP',
        value: (plan?.addons[index].price / 100).toFixed(2),
        items: [
          {
            item_id: plan?.addons[index].id,
            item_name: plan?.addons[index].name,
            coupon: lastValidatedCoupon.code,
            price: (plan?.addons[index].price / 100).toFixed(2),
            quantity: supplementIndex === -1 ? 1 : supplements[supplementIndex].quantity,
            item_list_id: plan?.addons[index].analytics?.item_list_id,
            item_list_name: plan?.addons[index].analytics?.item_list_name
          }
        ]
      })
      window.gtag('event', 'added_supplement', {
        page: 'sign_up_supplement_post_checkout',
        addon_id: plan?.addons[index].id,
        addon_name: plan?.addons[index].name,
        quantity: supplementIndex === -1 ? 1 : supplements[supplementIndex].quantity
      })
    }
  }

  const handleRemoveSupplement = (supplementId: string) => {
    if (supplements) {
      const index = supplements.findIndex(supplement => supplement.id === supplementId)
      const plansAddonsIndex = plan?.addons?.findIndex(addon => addon.id === supplementId)

      if (index !== -1) {
        if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
          window.gtag('event', 'remove_from_cart', {
            currency: 'GBP',
            items: [
              {
                item_id: plan?.addons[plansAddonsIndex].id,
                item_name: plan?.addons[plansAddonsIndex].name,
                coupon: lastValidatedCoupon.code,
                quantity: supplements[index].quantity - 1,
                item_list_id: plan?.addons[plansAddonsIndex].analytics?.item_list_id,
                item_list_name: plan?.addons[plansAddonsIndex].analytics?.item_list_name
              }
            ]
          })

          window.gtag('event', 'removed_supplement', {
            page: 'sign_up_supplement_post_checkout',
            addon_id: plan?.addons[plansAddonsIndex].id,
            addon_name: plan?.addons[plansAddonsIndex].name,
            quantity: supplements[index].quantity - 1
          })
        }
        if (supplements[index].quantity > 1) {
          const supplementsArr = [...supplements]
          supplementsArr[index].quantity = supplementsArr[index].quantity - 1
          setSupplements(supplementsArr)
        } else {
          setSupplements(supplements.filter(supplement => supplement.id !== supplementId))
        }
        setSupplementCount(curr => curr - 1)

        if (typeof window.gtag !== 'undefined' && plan?.addons?.length > 0) {
          window.gtag('event', 'removed_supplement', {
            page: 'sign_up_supplement_post_checkout',
            addon_id: plan?.addons[plansAddonsIndex].id,
            addon_name: plan?.addons[plansAddonsIndex].name,
            quantity: supplements[index].quantity - 1
          })
        }
      }
    }
  }

  const getSupplementQuantity = (supplementId: string) => {
    if (supplements) {
      const index = supplements.findIndex(supplement => supplement.id === supplementId)
      if (index !== -1) {
        return supplements[index].quantity
      }
    }
    return 0
  }

  const getNormalDiscountPrice = (price: number, supplementId: string) => {
    const discountAppliedToAll = dogInfo?.defaultCoupon?.applicableIds?.length === 0 ? true : false
    if (price > 0 && dogInfo.defaultCoupon?.code) {
      if (dogInfo.defaultCoupon.type === 'percentage') {
        if (discountAppliedToAll) {
          return price - (dogInfo.defaultCoupon.amount / 100) * price
        } else {
          if (dogInfo?.defaultCoupon?.applicableIds.includes(supplementId)) {
            return price - (dogInfo.defaultCoupon.amount / 100) * price
          } else {
            return price
          }
        }
      }
      return price
    }
    return price
  }

  const getSelectedSupplements = () => {
    const selectedSupplements = supplements.map(supplement => {
      return { id: supplement.id, quantity: supplement.quantity }
    })
    return selectedSupplements
  }

  const getSupplementHighlight = (highlight: any) => {
    // Handle Plant based treats
    if (highlight && highlight[0]?.includes('Plant')) {
      return highlight[0]
    }
    // Create highlight from two highlights
    if (highlight.length > 1) {
      return highlight[0]?.replace('100% British', 'British') + ' & ' + highlight[1].replace('100% British', '')
    }

    return highlight[0]?.replace('British', '').replace('100%', 'British')
  }

  if (isLoading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  return (
    !isLoading && (
      <>
        <div>
          <Formik
            initialValues={{ supplements: [] }}
            validationSchema={SupplementsSchema}
            onSubmit={async () => {
              if (supplementCount === 0) {
                navigate('/signup/thankyou/')
              } else {
                setSubmitting(true)
                const selectedSupplements = getSelectedSupplements()
                // Get addons already on plan
                const addonsSelected = orderSummary.addons
                  .filter(a => a.quantity > 0)
                  .map(a => ({ id: a.id, quantity: a.quantity }))

                // Combine all addons for payload
                const allAddons = [...selectedSupplements, ...addonsSelected]
                const dataToForward: any = {
                  addons: allAddons,
                  subscriptionId: subscriptionId,
                  source: 'signup'
                }
                await dispatch(createShippingConfirmDogsExistingAddons(dataToForward))
                // Supplement count on submit
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'supplement_count_end', {
                    page: 'sign_up_supplement_post_checkout',
                    quantity: supplementCount
                  })
                }

                let supplementTotal = 0
                supplements.map(a => {
                  supplementTotal += a.quantity * a.price
                })
                // Supplement difference
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'supplement_count_difference', {
                    page: 'sign_up_supplement_post_checkout',
                    difference: supplementCount - supplementChange,
                    value: supplementTotal - supplementValue
                  })
                }
                if (!errorMessage) {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'clicked_next', {
                      page: 'sign_up_supplement_post_checkout'
                    })
                  }
                  setAddTreatModalShow(true)
                }
                setSubmitting(false)
              }
            }}
            validateOnMount
          >
            {props => {
              const getPackSize = (planDescription: string) => {
                if (!planDescription) return
                let index = planDescription.lastIndexOf('(')
                let splitArr = planDescription.split('(', index)
                let description = splitArr?.[0]?.trim()
                let packSize = splitArr?.length && splitArr[splitArr.length - 1]
                splitArr = (packSize && packSize?.split(')')) || []
                packSize = splitArr?.length && splitArr[0].trim()
                return [description, packSize]
              }
              const outOfStock = supplementDetail[0]?.status === 'OUTOFSTOCK'

              return (
                <div className="relative">
                  <div className="container mx-auto md:mx-auto">
                    {subscriptionId && (
                      <>
                        <div className="my-6">
                          <h1
                            data-testid="howAboutSomeTreats"
                            className="p-4 text-center text-3xl tracking-tight sm:text-4xl font-serif font-medium"
                          >
                            Supplements and extras
                          </h1>
                          <p className="text-center sm:text-lg">
                            Although our recipes are nutritionally complete, some dogs can benefit from a little extra
                            support, <br /> which is why we've created a range of healthy, easy-to-serve supplements.
                          </p>
                        </div>

                        <div className="md:p-4">
                          <h1
                            data-testid="ourRecommendation"
                            className="inline-block py-3 px-6 text-lg tracking-tight md:text-2xl font-bold font-sans text-center w-full"
                          >
                            We think {getDogNames(dogInfo.dogs)} will love this
                          </h1>

                          <div
                            className={`ml-[2%] -mr-[2%] flex h-[30rem] w-[96%] flex-wrap overflow-hidden bg-background md:h-96 lg:h-60 lg:min-w-[900px] lg:flex-nowrap ${
                              getSupplementQuantity(supplementDetail[0]?.id) > 0
                                ? `shadow-[0_0_0_4px_rgba(0,102,133,1)]`
                                : ''
                            } flex flex-wrap rounded-3xl lg:mx-auto lg:w-3/4`}
                          >
                            <div className="relative flex h-1/2 w-1/2 pt-4 md:h-[64%] md:pl-8 lg:h-full lg:w-full lg:max-w-[256px] lg:pl-0 lg:pt-0">
                              <CdnImage
                                url={supplementDetail[0]?.images?.secondary}
                                alt={supplementDetail[0]?.name}
                                steps={[400]}
                                className="lg:w[100%] rounded-3xl object-top md:h-[86%] md:w-[86%] block lg:h-[100%] lg:rounded-none lg:object-cover"
                              />
                              {supplementDetail[0]?.limitedEdition && !supplementDetail[0]?.isNew && (
                                <img
                                  style={{ lineHeight: '1rem', right: '0.5rem' }}
                                  className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                  src={limitedEditionSticker}
                                  alt="Limited Edition"
                                />
                              )}
                              {supplementDetail[0]?.isNew && (
                                <span
                                  style={{ lineHeight: '1rem', right: '0.5rem' }}
                                  className="index-1 absolute -top-4 left-4 md:-top-0 lg:left-0 inline-flex w-24 h-24 lg:h-20 lg:w-20 -rotate-12 md:h-20 md:w-20 xl:inline-flex"
                                >
                                  <img
                                    className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                    src={NewSticker}
                                    alt="New"
                                  />
                                </span>
                              )}
                            </div>
                            <div className="flex h-1/3 w-1/2 justify-between p-3 pb-0 md:p-4 md:pb-0 lg:w-full lg:px-6 lg:pb-4 xl:p-6">
                              <div className="ml-0 w-full md:-ml-2 lg:-ml-6">
                                <div className="flex items-center">
                                  <h4 className="text-base font-sans font-semibold pb-2">
                                    {supplementDetail[0]?.name}
                                  </h4>
                                </div>

                                <p className="flex w-full items-center text-left text-xs text-black md:text-sm">
                                  {getPackSize(supplementDetail[0]?.shortDescription)?.[0] ?? ''}
                                </p>

                                <div className="flex flex-col md:flex-row justify-start items-start w-full md:w-11/12">
                                  {supplementDetail[0]?.bullets.length > 0 && (
                                    <div
                                      className={`my-2 md:my-4 flex flex-row mx-auto justify-start items-center w-full ${supplementDetail[0]?.highlights.length > 0 ? 'md:w-1/2' : 'md:w-full'}`}
                                    >
                                      <img width={20} src={BulletPointImage} alt={'British Flag'} />
                                      <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {supplementDetail[0].bullets[0]}
                                      </span>
                                    </div>
                                  )}
                                  {supplementDetail[0]?.highlights.length > 0 && (
                                    <div
                                      className={`my-2 md:my-4 flex flex-row mx-auto justify-start items-center w-full ${supplementDetail[0]?.bullets.length > 0 ? 'md:w-1/2' : 'md:w-full'}`}
                                    >
                                      <img width={20} src={BritishFlag} alt={'British Flag'} />
                                      <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                        {getSupplementHighlight(supplementDetail[0].highlights)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="hidden md:flex justify-start">
                                  <button
                                    className="btn-secondary mb-4 text-sm"
                                    onClick={() => {
                                      setModalShow(true)
                                      setModalActiveRecipe(supplementDetail[0])
                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'view_item', {
                                          currency: 'GBP',
                                          items: [
                                            {
                                              item_id: supplementDetail[0]?.id,
                                              item_name: supplementDetail[0]?.name,
                                              coupon: lastValidatedCoupon.code,
                                              item_list_id: supplementDetail[0].analytics?.item_list_id,
                                              item_list_name: supplementDetail[0].analytics?.item_list_name
                                            }
                                          ]
                                        })
                                      }
                                    }}
                                  >
                                    Additional information
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* Desktop view */}
                            <div className="mt-12 ml-0 hidden h-1/2 w-1/2 flex-col items-center gap-y-4 text-left md:-mt-4 md:-ml-2 md:flex lg:mr-8 lg:mt-16 lg:ml-0 lg:w-1/3">
                              {(supplementDetail[0]?.price / 100)?.toFixed(2) !== 'NaN' && (
                                <p className={`text-xl font-bold lg:text-2xl`}>
                                  {getNormalDiscountPrice(supplementDetail[0]?.price, supplementDetail[0]?.id) !==
                                    supplementDetail[0]?.price && (
                                    <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                      £{(supplementDetail[0]?.price / 100)?.toFixed(2)}
                                    </span>
                                  )}
                                  <span className={'text-[#004355]'}>
                                    {getNormalDiscountPrice(supplementDetail[0]?.price, supplementDetail[0]?.id) > 0
                                      ? `£${getNormalDiscountPrice(
                                          supplementDetail[0]?.price / 100,
                                          supplementDetail[0]?.id
                                        )?.toFixed(2)}`
                                      : `FREE`}
                                  </span>
                                </p>
                              )}

                              <div className="flex w-[200px] flex-col items-center gap-y-4">
                                {getSupplementQuantity(supplementDetail[0]?.id) === 0 && (
                                  <div className="min-h-[50px]">
                                    <button
                                      data-testid="treatsAddBtn"
                                      className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                      disabled={disableSupplementAddButton(supplementDetail[0]?.id, outOfStock)}
                                      onClick={() =>
                                        handleAddSupplement(
                                          supplementDetail[0]?.id,
                                          supplementDetail[0]?.price,
                                          outOfStock,
                                          supplementDetail[0]?.name
                                        )
                                      }
                                    >
                                      {outOfStock ? 'Out of stock' : 'Add'}
                                    </button>
                                  </div>
                                )}

                                {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                  <div>
                                    <div className="flex items-center justify-center">
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <button
                                          className="btn-cta mr-4 h-10 w-10"
                                          type="button"
                                          onClick={() => handleRemoveSupplement(supplementDetail[0]?.id)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <Field
                                          name="treats"
                                          type="text"
                                          placeholder="0"
                                          className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                          value={getSupplementQuantity(supplementDetail[0]?.id)}
                                          inputMode="none"
                                          onFocus={(e: any) => e.target.blur()}
                                        />
                                      )}
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <button
                                          className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                          type="button"
                                          disabled={disableSupplementAddButton(supplementDetail[0]?.id, outOfStock)}
                                          onClick={() =>
                                            handleAddSupplement(
                                              supplementDetail[0]?.id,
                                              supplementDetail[0]?.price,
                                              outOfStock,
                                              supplementDetail[0]?.name
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* Mobile view */}
                            <div className="w-full flex flex-col items-center justify-center px-4 md:ml-0 md:hidden md:pt-4">
                              <p className={`mb-2 ml-2 text-xl font-bold lg:text-2xl`}>
                                {getNormalDiscountPrice(supplementDetail[0]?.price, supplementDetail[0]?.id) !==
                                  supplementDetail[0]?.price && (
                                  <span className={`strikethrough ml-2 text-[#5C5C5C]`}>
                                    £{(supplementDetail[0]?.price / 100)?.toFixed(2)}
                                  </span>
                                )}
                                <span className={'ml-2 text-[#004355]'}>
                                  {getNormalDiscountPrice(supplementDetail[0]?.price, supplementDetail[0]?.id) > 0
                                    ? `£${getNormalDiscountPrice(
                                        supplementDetail[0]?.price / 100,
                                        supplementDetail[0]?.id
                                      )?.toFixed(2)}`
                                    : `FREE`}
                                </span>
                              </p>

                              <div className="flex w-[250px] flex-col items-center gap-y-4">
                                {getSupplementQuantity(supplementDetail[0]?.id) === 0 && (
                                  <div className="min-h-[50px]">
                                    <button
                                      className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                      disabled={disableSupplementAddButton(supplementDetail[0]?.id, outOfStock)}
                                      onClick={() =>
                                        handleAddSupplement(
                                          supplementDetail[0]?.id,
                                          supplementDetail[0]?.price,
                                          outOfStock,
                                          supplementDetail[0]?.name
                                        )
                                      }
                                    >
                                      {outOfStock ? 'Out of stock' : 'Add'}
                                    </button>
                                  </div>
                                )}

                                {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                  <div>
                                    <div className="flex items-center justify-center">
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <button
                                          className="btn-cta mr-2 h-10 w-10"
                                          type="button"
                                          onClick={() => handleRemoveSupplement(supplementDetail[0]?.id)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <Field
                                          name="treats"
                                          type="text"
                                          placeholder="0"
                                          className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                          value={getSupplementQuantity(supplementDetail[0]?.id)}
                                          inputMode="none"
                                          onFocus={(e: any) => e.target.blur()}
                                        />
                                      )}
                                      {getSupplementQuantity(supplementDetail[0]?.id) > 0 && (
                                        <button
                                          className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                          type="button"
                                          disabled={disableSupplementAddButton(supplementDetail[0]?.id, outOfStock)}
                                          onClick={() =>
                                            handleAddSupplement(
                                              supplementDetail[0]?.id,
                                              supplementDetail[0]?.price,
                                              outOfStock,
                                              supplementDetail[0]?.name
                                            )
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <button
                                  className="btn-secondary mb-4 text-sm"
                                  onClick={() => {
                                    setModalShow(true)
                                    setModalActiveRecipe(supplementDetail[0])
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'view_item', {
                                        currency: 'GBP',
                                        items: [
                                          {
                                            item_id: supplementDetail[0].id,
                                            item_name: supplementDetail[0].name,
                                            coupon: lastValidatedCoupon.code,
                                            item_list_id: supplementDetail[0].analytics?.item_list_id,
                                            item_list_name: supplementDetail[0].analytics?.item_list_name
                                          }
                                        ]
                                      })
                                    }
                                  }}
                                >
                                  Additional information
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="mb-8 mx-auto py-10">
                            <ul className="grid grid-cols-2 gap-y-6 gap-x-2 sm:gap-x-8 lg:grid-cols-3 xl:grid-cols-4 2xl:mx-auto 2xl:max-w-[75%]">
                              {supplementDetail &&
                                supplementDetail.slice(1).map(
                                  (
                                    supplement: {
                                      id: string
                                      name: string
                                      imageUrlNormal: string
                                      price: number
                                      id: string
                                      shortDescription: string
                                      status: string
                                    },
                                    _i: any
                                  ) => {
                                    const outOfStock = supplement.status === 'OUTOFSTOCK'

                                    return (
                                      <li
                                        className={`relative mx-auto ml-[2%] -mr-[2%] w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-background md:mr-0 md:ml-0 md:flex-col ${
                                          getSupplementQuantity(supplement?.id) > 0
                                            ? 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'
                                            : 'shadow-[0_0_0_4px_rgba(209,213,218,0)]'
                                        }`}
                                        key={supplement.id}
                                      >
                                        {supplement?.isNew && (
                                          <span
                                            style={{ lineHeight: '1rem', right: '0.5rem' }}
                                            className="index-1 absolute -top-8 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                          >
                                            <img
                                              className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                              src={NewSticker}
                                              alt="New"
                                            />
                                          </span>
                                        )}
                                        <div className="flex h-full flex-1 flex-col justify-between text-center mt-0">
                                          <div className="flex h-full flex-col">
                                            <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                              <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:rounded-t-3xl mt-0 2xl:max-h-[485px]">
                                                <CdnImage
                                                  url={supplement?.images?.secondary}
                                                  alt={supplement.name}
                                                  steps={[450]}
                                                  className={`w-full scale-100 transform xs:min-h-[150px] object-cover md:max-h-full lg:block bg-[#F6E9DB]`}
                                                />
                                                {supplement.limitedEdition && !supplement?.isNew && (
                                                  <img
                                                    style={{ lineHeight: '1rem', right: '0.5rem' }}
                                                    className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                                    src={limitedEditionSticker}
                                                    alt="Limited Edition"
                                                  />
                                                )}
                                              </div>
                                              {outOfStock && (
                                                <span
                                                  style={{ lineHeight: '1rem', right: '0.5rem' }}
                                                  className="absolute top-[2%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                                >
                                                  Out of stock
                                                </span>
                                              )}
                                              <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                                <h4 className="text-center text-sm text-[#004355] sm:text-xl md:mb-4 font-sans font-semibold pb-2">
                                                  {supplement?.name}
                                                </h4>
                                                <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                                  {getPackSize(supplement?.shortDescription)?.[0] ?? ''}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full ml-4 md:ml-0 md:mb-4">
                                            {supplement?.bullets.length > 0 && (
                                              <div className="flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                                <img width={20} src={BulletPointImage} alt={'Logo'} />
                                                <span className="ml-2 mt-[0.3rem] inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                                  {supplement.bullets[0]}
                                                </span>
                                              </div>
                                            )}
                                            {supplement?.highlights?.length > 0 && (
                                              <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                                {supplement.highlights[0].includes('Supplement') && (
                                                  <img width={20} src={BulletPointImage} alt={'Logo'} />
                                                )}
                                                {!supplement.highlights[0].includes('Supplement') && (
                                                  <img width={20} src={BritishFlag} alt={'British Flag'} />
                                                )}
                                                <span
                                                  className={`${
                                                    supplement.highlights[0].includes('Supplement')
                                                      ? 'mt-[0.3rem]'
                                                      : 'mt-0'
                                                  } ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm`}
                                                >
                                                  {getSupplementHighlight(supplement.highlights)}
                                                </span>
                                              </div>
                                            )}
                                          </div>

                                          <div className="mx-auto flex flex-col items-center justify-center text-center">
                                            <p className={`text-xl font-bold lg:text-2xl`}>
                                              {getNormalDiscountPrice(supplement?.price, supplement.id) !==
                                                supplement.price && (
                                                <span className="strikethrough mr-2 text-[#5C5C5C]">
                                                  £{(supplement?.price / 100)?.toFixed(2)}
                                                </span>
                                              )}
                                              <span
                                                className={`${
                                                  getNormalDiscountPrice(supplement.price, supplement.id) > 0
                                                    ? 'ml-2'
                                                    : ''
                                                } text-[#004355]`}
                                              >
                                                {getNormalDiscountPrice(supplement.price, supplement.id) > 0
                                                  ? `£${getNormalDiscountPrice(
                                                      supplement.price / 100,
                                                      supplement.id
                                                    )?.toFixed(2)}`
                                                  : `FREE`}
                                              </span>
                                            </p>
                                          </div>

                                          <div className="flex items-center justify-center">
                                            {getSupplementQuantity(supplement.id) > 0 && (
                                              <button
                                                className="btn-cta mr-2 h-10 w-10 md:mr-4"
                                                type="button"
                                                onClick={() => handleRemoveSupplement(supplement.id)}
                                              >
                                                -
                                              </button>
                                            )}
                                            {getSupplementQuantity(supplement.id) > 0 && (
                                              <Field
                                                name="treats"
                                                type="text"
                                                placeholder="0"
                                                className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-2 text-center md:px-4"
                                                value={getSupplementQuantity(supplement.id)}
                                                inputMode="none"
                                                onFocus={(e: any) => e.target.blur()}
                                              />
                                            )}
                                            {getSupplementQuantity(supplement.id) > 0 && (
                                              <button
                                                className="btn-cta ml-2 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter md:ml-4"
                                                type="button"
                                                disabled={disableSupplementAddButton(supplement.id, outOfStock)}
                                                onClick={() =>
                                                  handleAddSupplement(
                                                    supplement.id,
                                                    supplement.price,
                                                    outOfStock,
                                                    supplement.name
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            )}
                                            {getSupplementQuantity(supplement.id) === 0 && (
                                              <div className="flex min-h-[50px] flex-col justify-center">
                                                <button
                                                  data-testid="treatsAddBtn"
                                                  type="button"
                                                  className={`btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:grayscale disabled:filter`}
                                                  disabled={disableSupplementAddButton(supplement.id, outOfStock)}
                                                  onClick={() =>
                                                    handleAddSupplement(
                                                      supplement.id,
                                                      supplement.price,
                                                      outOfStock,
                                                      supplement.name
                                                    )
                                                  }
                                                >
                                                  {outOfStock ? 'Out of stock' : 'Add'}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                          <div className="my-2 text-center pb-2 md:pb-4">
                                            <button
                                              className="btn-secondary mx-auto mb-2 text-xs sm:text-sm md:mb-0"
                                              onClick={() => {
                                                setModalShow(true)
                                                setModalActiveRecipe(supplement)
                                                if (typeof window.gtag !== 'undefined') {
                                                  window.gtag('event', 'view_item', {
                                                    currency: 'GBP',
                                                    items: [
                                                      {
                                                        item_id: supplement.id,
                                                        item_name: supplement.name,
                                                        coupon: lastValidatedCoupon.code,
                                                        item_list_id: supplement.analytics?.item_list_id,
                                                        item_list_name: supplement.analytics?.item_list_name
                                                      }
                                                    ]
                                                  })
                                                }
                                              }}
                                            >
                                              Additional information
                                            </button>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <StickyFooter className="fixed bottom-0 left-0 right-0 z-[3] lg:max-h-20 bg-[#F8F2EC] w-full lg:w-11/12 mx-auto lg:rounded-t-3xl shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                    <div className="w-full lg:w-full flex flex-col lg:flex-row relative items-center justify-end mx-auto pb-2">
                      <div className="z-1 mb-2 lg:mb-0 text-center lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2">
                        <p>
                          Items selected <span className="font-bold bg-ddBlue p-1 rounded-md">{supplementCount}</span>
                        </p>
                      </div>
                      <button
                        data-testid="treatsNext"
                        disabled={submitting}
                        onClick={() => props.handleSubmit()}
                        type="button"
                        className={`btn-cta scale-animation !py-3 lg:!py-4 !text-xl !font-bold !flex justify-center items-center w-11/12 lg:w-auto lg:!px-24 z-2`}
                      >
                        {submitting ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          `${buttonText}`
                        )}
                        {!loading && (
                          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </StickyFooter>
                </div>
              )
            }}
          </Formik>
        </div>
        <RecipeOrTreatAlterModal show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />
        <AddTreatConfirmModal
          show={addTreatModalShow}
          setShow={setAddTreatModalShow}
          treatsAdded={supplements}
          page={'newCustomer'}
        />
        {errorMessage && (
          <ErrorModal
            show={showPopup}
            setShow={setShowPopup}
            errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
            url={errorMessage?.popup?.url ?? errorMessage?.url}
            action={errorMessage?.popup?.action ?? errorMessage?.action}
            actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
          />
        )}
      </>
    )
  )
}
export default Supplements
